<div
  class="modal-body"
  id="confirm-deny-modal"
>
  <h1>{{ 'companyAccessRequests.confirmDialog.denyTitle' | translate }}</h1>

  <form [formGroup]="form">
    <label
      class="required mt-3"
      for="notes"
      >{{ 'companyAccessRequests.confirmDialog.label' | translate }}</label
    >
    <textarea
      ngbAutofocus
      required
      id="notesInput"
      formControlName="notes"
      [placeholder]="'companyAccessRequests.confirmDialog.placeholder' | translate"
    >
    </textarea>
  </form>

  <div class="buttons-group">
    <button
      class="btn btn-primary decline-btn"
      [disabled]="isLoading"
      (click)="activeModal.dismiss()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
    <button
      [appBtnLoading]="isLoading"
      (click)="deny()"
      class="btn btn-primary submit-btn"
      [disabled]="form.invalid"
    >
      {{ 'common.buttons.deny' | translate }}
    </button>
  </div>
</div>
