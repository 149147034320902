import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';

import { CompanyAccessRequestsPageComponent } from './company-access-requests-page/company-access-requests-page.component';

const routes: Routes = [
  {
    path: '',
    component: CompanyAccessRequestsPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompanyAccessRequestsRoutingModule {}
