import { Component, Input, type OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { ToastrService } from '@core/services/toastr.service';

import { CompanyAccessRequestsService } from '../../company-access-requests.service';

@UntilDestroy()
@Component({
  selector: 'app-confirm-approve-modal',
  templateUrl: './confirm-approve-modal.component.html',
  styleUrls: ['./confirm-approve-modal.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ButtonLoadingDirective, TranslateModule],
})
export class ConfirmApproveModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly companyAccessRequestsService = inject(CompanyAccessRequestsService);
  private readonly toastr = inject(ToastrService);
  @Input() id: number;

  public isLoading = false;
  public form: UntypedFormGroup;

  public ngOnInit() {
    this.form = new UntypedFormGroup({
      notes: new UntypedFormControl('', [Validators.required]),
    });
  }

  public approve(): void {
    this.isLoading = true;

    this.companyAccessRequestsService
      .approve(this.id, this.form.controls.notes.value)
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.activeModal.close(res);
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}
